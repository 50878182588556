<template>
  <div class="videcam-edit" v-if="isAdmin">
    <main>
      <h1>Редактировать камеру</h1>

      <mts-input v-model="stream" label="Ключ потока" placeholder="Введите ключ" required />
      <br />

      <mts-input v-model="place" label="Город" placeholder="Введите название" required />
      <br />

      <mts-input v-model="description" label="Адрес" placeholder="Введите название" required />
      <br />

      <mts-button @click="submit" theme="dark">Редактировать</mts-button>
      <br />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTER_APP } from '@/store/const/type'
import { getAxiosVidecamById, getStream, putAxiosVidecam } from './func'
import { APP_ROUTE } from '@/const'
export default {
  name: 'index-edit',
  data() {
    return {
      id: '',
      stream: '',
      place: '',
      description: ''
    }
  },
  methods: {
    async submit() {
      const item = {
        id: this.id,
        place: this.place,
        description: this.description,
        stream: this.stream
      }
      const { id } = await putAxiosVidecam(item)
      await this.$router.push(`/${APP_ROUTE.LIVE}/${id}`)
    }
  },
  computed: {
    ...mapGetters({ isAdmin: GETTER_APP.IS_ADMIN })
  },
  async created() {
    const { id } = this.$route.params
    const item = await getAxiosVidecamById(id)
    this.id = item.id
    this.place = item.place
    this.description = item.description
    this.stream = getStream(item.stream_url)
  }
}
</script>

<style lang="scss">
@import './styles';
</style>
